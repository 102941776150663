<template>
  <div>
    <b-row class="my-4">
      <b-col cols="12">
        <h3 class="center text-info float-left text-uppercase">
          {{ $t('sections.stores') }}
        </h3>

        <router-link :to="{ name: 'mercadolivre-stores' }">
          <b-button size="sm" class="ml-3" variant="info">
            {{ $t('buttons.back') }}
          </b-button>
        </router-link>
      </b-col>
    </b-row>

    <form @submit.prevent="onSubmit" v-if="loaded">

      <!-- Nome -->
      <b-row>
        <b-col lg="6" sm="12" class="mb-3">
          <b-input-group :prepend="$t('inputs.nameEN')">
            <b-form-input
              v-model="model.nameEN"
              maxlength="26"
              type="text"
              required
            />
          </b-input-group>
        </b-col>

        <b-col lg="6" sm="12" class="mb-3">
          <b-input-group :prepend="$t('inputs.namePT')">
            <b-form-input
              v-model="model.namePT"
              maxlength="26"
              type="text"
              required
            />
          </b-input-group>
        </b-col>
      </b-row>

      <!-- Descricao -->
      <b-row>
        <b-col lg="6" sm="12" class="mb-3">
          <TextArea
            :label="$t('inputs.descriptionEN')"
            v-model="model.descriptionEN"
            maxlength="500"
            required
          />
        </b-col>

        <b-col lg="6" sm="12" class="mb-3">
          <TextArea
            :label="$t('inputs.descriptionPT')"
            v-model="model.descriptionPT"
            maxlength="500"
            required
          />
        </b-col>
      </b-row>

      <!-- URL -->
      <b-row>
        <b-col lg="6" sm="12" class="mb-3">
          <b-input-group :prepend="$t('inputs.urlEN')">
            <b-form-input
              v-model="model.urlEN"
              maxlength="500"
              type="url"
              required
            />
          </b-input-group>
        </b-col>

        <b-col lg="6" sm="12" class="mb-3">
          <b-input-group :prepend="$t('inputs.urlPT')">
            <b-form-input
              v-model="model.urlPT"
              maxlength="500"
              type="url"
              required
            />
          </b-input-group>
        </b-col>
      </b-row>

      <!-- Categoria -->
      <b-row>
        <b-col sm="12" class="mb-3" >
          <b-input-group :prepend="$t('inputs.category')">
            <b-form-select
              required
              v-model="model.categoryPT"
              :options="categories"
            />
          </b-input-group>
        </b-col>
      </b-row>

      <ImageUploader minWidth="250" minHeight="250" v-model="upload" v-if="model.id" />

      <b-table
        v-if="model.id"
        class="mt-3"
        show-empty
        responsive
        bordered
        striped
        hover
        :busy="loading"
        :items="model.images"
        :fields="fields"
        :emptyText="$t('messages.emptyTable')"
        :emptyFilteredText="$t('messages.emptyFilteredTable')"
      >
        <template v-slot:table-busy>
          <div
            class="
              text-center text-danger
              my-2
              align-items-center
              d-flex
              flex-column
            "
          >
            <b-spinner
              class="align-middle flex-direction-column mb-3"
            ></b-spinner>
            <strong>{{ $t('messages.loading') }}...</strong>
          </div>
        </template>

        <template v-slot:cell(preview)="data">
          <b-img
            :src="data.item.filename"
            height="50px"
            width="100px"
            thumbnail
          />
        </template>

        <template v-slot:cell(actions)="data">
          <p class="text-center m-0">
            <b-button
              size="sm"
              class="ml-1"
              :disabled="data.index == 0 || loading"
              variant="primary"
              @click="orderUp(data.item.id)"
            >
              <b-icon icon="caret-up-fill" aria-hidden="true"></b-icon>
            </b-button>

            <b-button
              size="sm"
              class="ml-1"
              :disabled="data.index == model.images.length - 1 || loading"
              variant="primary"
              @click="orderDown(data.item.id)"
            >
              <b-icon icon="caret-down-fill" aria-hidden="true"></b-icon>
            </b-button>

            <b-button
              size="sm"
              class="ml-1"
              variant="danger"
              :disabled="loading"
              @click="deleteImage(data.item.id)"
            >
              <b-icon icon="trash" aria-hidden="true"></b-icon>
            </b-button>

            <span class="mx-1" />
          </p>
        </template>
      </b-table>

      <b-row>
        <b-col lg="12" class="text-right">
          <b-button :disabled="loading" type="submit" variant="success">
            {{ $t('buttons.save') }}
          </b-button>
        </b-col>
      </b-row>
    </form>

    <b-row v-else>
      <b-col cols="12">
        <h5 class="text-center mt-4">{{ $t('messages.loading') }}...</h5>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import * as Sentry from '@sentry/browser';
import Toast from '../../../../assets/js/toast';
import MercadoLivreManage from '../../../../services/MercadoLivreManage';
import ImageUploader from '../../../../components/organisms/ImageUploader.vue';
import TextArea from '../../../../components/organisms/TextArea.vue';
import 'vue-datetime/dist/vue-datetime.css';

export default {
  components: {
    TextArea,
    ImageUploader,
  },

  data() {
    const categories = [
      'Acessórios para Veículos',
      'Antiguidades e Coleções',
      'Arte, Papelaria e Armarinho',
      'Brinquedos e Hobbies',
      'Calçados, Roupas e Bolsas',
      'Casa, Móveis e Decoração',
      'Eletrodomésticos',
      'Esportes e Fitness',
      'Festas e Lembrancinhas',
      'Games',
      'Indústria e Comércio',
      'Informática',
      'Livros, Revistas e Comics',
      'Mais Categorias',
      'Música, Filmes e Seriados',
      'Saúde',
    ];
    const data = {
      isEdit: false,
      loaded: false,
      loading: true,
      showDeleteModal: false,
      categories: categories.map((category) => ({
        text: category,
        value: category,
      })),
      model: {
        namePT: '',
        nameEN: '',
        descriptionEN: '',
        descriptionPT: '',
        categoryEN: '',
        categoryPT: '',
        image: '',
      },

      upload: {
        file: null,
        preview: null,
        blob: null,
      },
      stageList: [],
      daysOptions: [],
      fields: [
        { key: 'preview', label: this.$t('inputs.thumb'), sortable: false },
        { key: 'actions', label: this.$t('inputs.action') },
      ],
    };

    return data;
  },

  beforeMount() {
    this.onLoad();
  },

  methods: {
    async onLoad() {
      try {
        this.loading = true;

        await this.onLoadStore();

        this.loading = false;
        this.loaded = true;
      } catch (e) {
        Sentry.captureException(e);
        Toast.error(this, e);
      }
    },

    async onLoadStore() {
      try {
        const { id } = this.$route.params;

        if (id && Number(id)) {
          const xhr = await MercadoLivreManage.getById(id);

          this.model = {
            ...xhr,
            images: xhr.images.map((image) => ({
              ...image,
              filename: `${process.env.VUE_APP_S3_URL}/${image.filename}`,
            })).sort((a, b) => a.order - b.order),
          };

          this.isEdit = true;
        }

        return true;
      } catch (e) {
        Sentry.captureException(e);
        Toast.error(this, e);
        console.log('Falha ao carregar!', e);

        return false;
      }
    },

    async onSubmit() {
      try {
        this.loading = true;
        const action = this.isEdit ? 'update' : 'save';

        const data = {
          ...this.model,
          categoryEN: this.categories
            .find((category) => category.value === this.model.categoryPT).value,
        };

        await MercadoLivreManage[action](data, this.model.id);
        Toast.success(this, 'messages.saveSuccess');

        setTimeout(() => {
          this.$router.push({ name: 'mercadolivre-stores' });
          this.loading = false;
        }, 1000);
      } catch (e) {
        Sentry.withScope((scope) => {
          scope.setExtra('model', JSON.stringify(this.model, undefined, 2));
          Sentry.captureException(e);
        });

        Toast.error(this, e);
        this.loading = false;
      }
    },

    async onRemove() {
      try {
        this.loading = true;

        await MercadoLivreManage.delete(this.model.id);

        setTimeout(() => {
          this.$router.push({ name: 'mercadolivre-stores' });
          this.loading = false;
        }, 1000);
      } catch (e) {
        Sentry.withScope((scope) => {
          scope.setExtra('deleteId', this.model.id);
          Sentry.captureException(e);
        });

        Toast.error(this, e);
      }
    },

    async deleteImage(id) {
      this.loading = true;

      await MercadoLivreManage.deleteImage(id);

      await this.onLoadStore();

      this.loading = false;
    },

    async orderDown(id) {
      this.loading = true;

      const ids = this.model.images.map((i) => i.id);
      const idx = ids.indexOf(id);
      const buffer = ids[idx];
      ids[idx] = ids[idx + 1];
      ids[idx + 1] = buffer;

      await MercadoLivreManage.reorderImage({ ids });
      await this.onLoadStore();

      this.loading = false;
    },

    async orderUp(id) {
      this.loading = true;

      const ids = this.model.images.map((i) => i.id);
      const idx = ids.indexOf(id);
      const buffer = ids[idx];
      ids[idx] = ids[idx - 1];
      ids[idx - 1] = buffer;

      await MercadoLivreManage.reorderImage({ ids });
      await this.onLoadStore();

      this.loading = false;
    },
  },

  watch: {
    async upload(value) {
      try {
        if (!value.file || !value.file.name) {
          return;
        }
        this.loading = true;
        const fd = new FormData();
        if (value.file) {
          fd.append('file', value.blob, value.file.name);
          await MercadoLivreManage.uploadPicture(
            fd,
            this.model.id,
          );
        }

        this.upload = {
          file: null,
          preview: null,
          blob: null,
        };
        this.form = {};
        await this.onLoadStore();
        // toast sucesso
        this.loading = false;
      } catch (e) {
        Sentry.withScope((scope) => {
          scope.setExtra('model', JSON.stringify(this.model, undefined, 2));
          Sentry.captureException(e);
        });

        Toast.error(this, e);
        this.loading = false;
      }
    },
  },

};
</script>

<style lang="sass">
.vdatetime
  flex-grow: 1
</style>
