import Vue from 'vue';

export default {
  get: async (page, limit) => Vue.http.get(`mercadolivre/store/list/paginated${page ? `?page=${page}` : ''}${limit ? `&limit=${limit}` : ''}`)
    .then((res) => res.json()),

  getById: async (id) => Vue.http.get(`mercadolivre/store/${id}`)
    .then((res) => res.json()),

  getGallery: async (id) => Vue.http.get(`mercadolivre/store/gallery/${id}`)
    .then((res) => res.json()),

  update: async (model, id) => Vue.http.put(`mercadolivre/store/${id}`, model)
    .then((res) => res.json()),

  uploadPicture: async (model, id) => Vue.http.post(`mercadolivre/store/upload/${id}`, model)
    .then((res) => res.json()),

  reorderImage: async (model, id) => Vue.http.post(`mercadolivre/store/images/${id}/reorder/`, model)
    .then((res) => res.json()),

  deleteImage: async (imageId) => Vue.http.delete(`mercadolivre/store/images/${imageId}/`)
    .then((res) => res.json()),

  save: async (model) => Vue.http.post('mercadolivre/store', model)
    .then((res) => res.json()),

  delete: async (id) => Vue.http.delete(`mercadolivre/store/${id}`)
    .then((res) => res.json()),
};
